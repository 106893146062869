<template>
  <div class="h-full">
    <div class="suporte-cliente-tarefas cli-task-page">
      <div class="nav">
        <a href="/#/suporte">SL Suporte</a>
        <i class="fa fa-chevron-right" />
        <a href="/#/suporte/tarefas">Suas solicitações de suporte</a>
        <i class="fa fa-chevron-right" />
        <span class="nav-end">Detalhes do caso</span>
      </div>

      <div v-if="!loading && task" class="case">
        <div class="case-header">
          <div class="id">
            Atendimento ID {{task.id}}
            <span class="id-closed" v-if="!isOpened"><i class="fa-duotone fa-check-to-slot"/> Fechado em {{task.closedAt|formatDate}}</span>
            <span class="id-closed bg-green" v-if="task.public"><i class="fa-solid fa-bullhorn" /> Tarefa Pública</span>
            <span class="id-closed transparent" v-if="task.status.code === 'completed'"><check-svg /> Marcado como resolvido</span>
            <span class="id-closed transparent" v-if="task.status.code === 'recused'"><alert-svg /> Solicitação rejeitada</span>
            <span class="id-closed transparent" v-if="task.status.code === 'canceled'"><alert-svg /> Solicitação cancelada</span>
          </div>
          <div class="actions">
            <task-all-persons :task="task" class="m-l" />
            <follow-task :show-legend="true" @followersUpdate="updateFollowers" :task="task" is-gerencia class="m-l" />
            <u-btn @click="resolver" v-if="isOpened && !isMe" label="Resolver Caso" no-caps color="white" class="m-l text-grey-8 b-radius-3px font-13" />
            <u-btn @click="reabrir" v-if="task.internalStatus === 'closed'" label="Reabrir Caso" no-caps color="blue" class="m-l text-white b-radius-3px font-13" />
          </div>
        </div>
        <div class="case-tab">
          <div class="tab-header">Detalhes do atendimento</div>
          <div class="tab-content">
            <e-row class="def" mr>
              <e-col>
                <task-detail title="Assunto">{{ task.description }}</task-detail>
                <e-row mr>
                  <e-col>
                    <task-detail title="Criado">{{ task.createdAt|formatDate }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Última atualização">
                      <div v-if="task.dateModified">{{ task.dateModified|formatDate }}</div>
                      <div v-else>-</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Tipo de requisição">{{ task.type?.name }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Prioridade">{{ task.priority?.name ?? '-' }}</task-detail>
                  </e-col>
                </e-row>
                <task-detail title="Projeto">{{ task.project?.name ?? '-' }}</task-detail>
              </e-col>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Status"><strong>{{ task.status?.name ?? '-' }}</strong></task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Fila">{{ task.queue?.name ?? '-' }}</task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Relator">
                      <person-profile :person="task.author" disable-photo />
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Responsável principal">
                      <person-profile :person="task.assigned" disable-photo />
                    </task-detail>
                  </e-col>
                </e-row>
                <task-detail title="Previsão">
                  <deadline disable />
                </task-detail>
                <e-row mr>
                  <!--<e-col>
                    <task-detail title="Início do Trabalho">
                      <span v-if="task.startTime">{{ task.startTime.date|formatDateDistance }}</span>
                      <span v-else>-</span>
                    </task-detail>
                  </e-col>-->
                  <e-col>
                    <task-detail title="Esforço total previsto">
                      {{ task.expectedHours }}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail>
                      <template v-slot:title>
                        <div class="title">
                          Tempo trabalhado
                          <i class="fa-duotone fa-square-question">
                            <u-tooltip :offset="[10,10]">
                              Soma das horas trabalhadas de todos os envolvidos no atendimento
                            </u-tooltip>
                          </i>
                        </div>
                      </template>
                      {{ task.workedHours }}
                    </task-detail>
                  </e-col>
                </e-row>
                <task-detail v-if="task.progress">
                  <template v-slot:title>
                    <div class="title">
                      Progresso
                      <i class="fa-duotone fa-square-question">
                        <u-tooltip :offset="[5,5]">
                          O progresso pode não ser exato ou estar sendo contabilizado pelo responsável pelo atendimento.
                        </u-tooltip>
                      </i>
                    </div>
                  </template>
                  <task-progress />
                </task-detail>
<!--                <task-detail>
                  <template v-slot:title>
                    <div class="title">Teste</div>
                  </template>
                  {{ task.status.name }}
                </task-detail>-->
              </e-col>
            </e-row>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header with-btn">
            <div>Caso</div>
            <div>
              <u-btn v-if="!replyTab && isOpened" @click="reply" label="Responder" color="orange-9" class="b-radius-3px font-13" no-caps />
            </div>
          </div>
          <div class="tab-content no-padder">
            <div v-if="replyTab" class="message reply">
              <task-reply :task="task" @update="updateComment" @close="replyTab = false" />
            </div>
            <div v-for="comment in comments" :key="'comment-' + comment.id" class="message">
              <div class="mleft">
                <person-profile :person="comment.author" disable-name />
                <div class="m-t-sm">{{comment.author.name}}</div>
                <div class="m-t-sm">
                  {{comment.createdAt|formatDateDistance}}
                  <div class="font-11 m-t-xs text-grey">{{comment.createdAt|formatDate}}</div>
                </div>
              </div>
              <div class="mright">
                <div class="task-content" v-html="comment.text"></div>
                <task-attachments-files :task="task" :comment="comment" class="m-t" is-gerencia />
              </div>
            </div>
            <div class="message">
              <div class="mleft">
                <person-profile :person="task.author" disable-name />
                <div class="m-t-sm">{{task.author.name}}</div>
                <div class="m-t-sm">
                  {{task.createdAt|formatDateDistance}}
                  <div class="font-11 m-t-xs text-grey">{{task.createdAt|formatDate}}</div>
                </div>
              </div>
              <div class="mright">
                <div class="task-content" v-html="task.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="case">
        <sl-loading class="size-18" content="" />
      </div>
    </div>
  </div>
</template>

<script>

import criarTarefa from "components/suporte/cliente/window/criarTarefa"
import Mixin from "components/suporte/cliente/mixin"
import SlLoading from "components/layout/components/Loading.vue"
import {find, closeTask, reopenTask} from "@/domain/tarefas/services"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import TaskDetail from "components/suporte/cliente/components/include/TaskDetail.vue"
import PersonProfile from "components/suporte/cliente/components/include/PersonProfile"
import Deadline from "components/suporte/components/task/include/Deadline.vue"
import TaskProgress from "components/suporte/components/task/include/Progress.vue"
import FollowTask from "components/sltarefas/components/tarefa/follow-task.vue"
import TaskAllPersons from "components/suporte/cliente/components/include/TaskAllPersons.vue"
import TaskReply from "components/suporte/cliente/components/include/TaskReply.vue";
import TaskAttachmentsFiles from "components/suporte/components/task/include/attachment/files.vue";
import CheckSvg from "@/assets/images/CheckSvg.vue"
import AlertSvg from "@/assets/images/AlertSvg.vue"

export default {
  name: "ClienteTarefa",
  provide: function () {
    return {
      taskContainer: this
    }
  },
  mixins: [Mixin],
  computed: {
    comments () {
      if (!this.task.comments) {
        return []
      }
      return this.task.comments.slice().sort((a, b) => b.id - a.id)
    },
    me () {
      return this.$uloc.auth.session.user.person
    },
    isMe () {
      return this.task.author.oldId === this.me
    },
    isOpened () {
      return this.task.internalStatus === 'opened'
    }
  },
  data () {
    return {
      loading: true,
      task: null,
      replyTab: false
    }
  },
  components: {
    AlertSvg,
    CheckSvg,
    TaskAttachmentsFiles,
    TaskReply,
    TaskAllPersons,
    FollowTask,
    TaskProgress,
    Deadline,
    PersonProfile,
    TaskDetail,
    ERow,
    ECol,
    SlLoading
  },
  beforeCreate() {
    document.body.classList.add('cli-task-body')
  },
  beforeDestroy() {
    document.body.classList.remove('cli-task-body')
  },
  mounted() {
    this.load()
  },
  watch: {
    '$route.params.id' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.replyTab = false
      this.loading = true
      find(this.$route.params.id, true)
          .then(response => {
            this.task = response.data
            setTimeout(() => {
              document.title = `Caso #${this.task.id} - Suporte SL`
            }, 2)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    criarTarefa: criarTarefa,
    open () {
      this.criarTarefa()
    },
    updateFollowers (followers) {
      console.log(followers)
    },
    reply () {
      this.replyTab = true
    },
    updateComment (comment) {
      this.load()
    },
    resolver () {
      this.$uloc.dialog({
        title: 'Resolver caso',
        message: 'Você tem certeza que deseja definir este caso como resolvido?',
        cancel: 'Cancelar',
        ok: 'Sim',
        color: 'primary'
      }).then(() => {
        this.$uloc.loading.show()
        closeTask(this.task, true)
            .then(response => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              console.log(error)
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
      })
    },
    reabrir () {
      this.$uloc.dialog({
        title: 'Reabrir caso',
        message: 'Você tem certeza que deseja reabir este caso?',
        cancel: 'Cancelar',
        ok: 'Sim, reabir',
        color: 'primary'
      }).then(() => {
        this.$uloc.loading.show()
        reopenTask(this.task, true)
            .then(response => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              console.log(error)
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
      })
    }
  },
  meta: {
    title: 'Tarefa'
  }
}
</script>
